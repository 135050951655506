import { gettext } from "#js/components/i18n"
import { mount } from "#js/components/utils"

/**
 * Fade out an element.
 * @param  {HTMLElement} element - The element to fade out.
 * @returns {void}
 */
export function fadeOut(element) {
  setTimeout(() => {
    element.classList.remove("show")
    setTimeout(() => element.remove(), 500)
  }, 12000)
}

mount(fadeOut, ".snackbar--fadeout.show")

/**
  Display a snackbar dialog.
  @param {string} msg - The message to display.
  @param {string} type - The type of message. Can be 'success', 'info', 'warning' or 'error'.
  @param {string} actionText - The text to display on the action button.
  @param {Function} actionCallback - The callback to execute when the action button is clicked.
  @param {boolean} fade - Whether to fade out the snackbar after a certain amount of time.
 */
export function message(msg, type, actionText, actionCallback, fade = true) {
  const element = document.createElement("div")
  element.classList.add(type, "snackbar")
  if (fade) {
    element.classList.add("snackbar--fadeout", "show")
  }
  element.innerHTML = `
  <div class="snackbar__message">${msg}</div>
  <div class="snackbar__actions"></div>
  <div class="snackbar__bar ${type}"></div>
  `
  if (actionText !== undefined) {
    const action = document.createElement("button")
    action.classList.add("button", "button--inline", "button--text")
    action.innerHTML = actionText
    action.addEventListener("click", () => actionCallback())
    element.querySelector(".snackbar__actions").appendChild(action)
  }
  document.getElementById("message-container").appendChild(element)
  if (fade) {
    fadeOut(element)
  }
}

globalThis.snackbarMessage = message

/**
 * Copy text to clipboard.
 * @param {string} text - Text to copy.
 * @returns {void}
 */
export function pbcopy(text) {
  navigator.clipboard.writeText(text)
  message(gettext("Copied to clipboard:") + " " + text, "success")
}

globalThis.pbcopy = pbcopy
